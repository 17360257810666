import React, { useState } from 'react';
import axios from 'axios';
import { FaUser, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import BackendURL from '../../BackendURL';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    Name: '',
    ContactNo: '',
    Email: '',
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setResponseMessage('');

    try {
      // Pehle WhatsApp ka URL generate kar lo
      const { Name, ContactNo, Email } = formData;
      const whatsappMessage = `New Contact Form Submission:\n\nName: ${Name}\nContact No: ${ContactNo}\nEmail: ${Email}`;
      const whatsappURL = `https://wa.me/918770608670?text=${encodeURIComponent(whatsappMessage)}`;

      // **Step 1: Pehle WhatsApp kholne ki koshish karo**
      window.open(whatsappURL, '_blank');

      // **Step 2: Phir database me data save karo**
      const response = await axios.post(`${BackendURL}/api/contactus`, formData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data && response.data.success) {
        setResponseMessage('Your message has been sent successfully!');
        setFormData({ Name: '', ContactNo: '', Email: '' });
      } else {
        setResponseMessage(response.data.message || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      setResponseMessage(error.response?.data?.message || 'Network error. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="custom-form-wrapper">
      <h2 className="form-heading">📩 Get in Touch with <span>Technoflex</span></h2>
      <p className="form-subheading">We'd love to hear from you! Fill out the form below and we'll get back to you shortly.</p>

      <form className="custom-form-container" onSubmit={handleSubmit}>
        <div className="custom-form-section">
          <div className="custom-input-box">
            <label><FaUser /> Name *</label>
            <input type="text" name="Name" value={formData.Name} onChange={handleInputChange} required />
          </div>
        </div>

        <div className="custom-form-section">
          <div className="custom-input-box">
            <label><FaPhoneAlt /> Contact No *</label>
            <input type="tel" name="ContactNo" value={formData.ContactNo} onChange={handleInputChange} required />
          </div>

          <div className="custom-input-box">
            <label><FaEnvelope /> Email *</label>
            <input type="email" name="Email" value={formData.Email} onChange={handleInputChange} required />
          </div>
        </div>

        <button type="submit" className="custom-submit-btn" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>

        {responseMessage && <p className={responseMessage.includes('success') ? 'custom-success-msg' : 'custom-error-msg'}>{responseMessage}</p>}
      </form>
    </div>
  );
};

export default ContactForm;
