import React, { useState, useEffect } from "react";
import ContactForm from "./ContactForm";
import "./ContactPopup.css";

const ContactPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Automatically open the popup when the website loads
    setIsOpen(true);
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-btn" onClick={closeModal}>
              ×
            </button>
            {/* <h2>Contact Us</h2> */}
            <ContactForm />
          </div>
        </div>
      )}
    </>
  );
};

export default ContactPopup;
